/** @format */

// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select {
	color: var(--kt-form-select-color);
	background-color: var(--kt-form-select-bg);
	background-image: var(--kt-form-select-indicator);
	border: $form-select-border-width solid var(--kt-form-select-border-color);
	@include box-shadow(var(--kt-form-select-box-shadow));
	appearance: none;

	&:focus {
		border-color: var(--kt-form-select-focus-border-color);
		@if $enable-shadows {
			@include box-shadow(
				var(--kt-form-select-box-shadow),
				var(--kt-form-select-focus-box-shadow)
			);
		} @else {
			// Avoid using mixin so we can pass custom focus shadow properly
			box-shadow: var(--kt-form-select-focus-box-shadow);
		}
	}

	&:disabled {
		color: var(--kt-form-select-disabled-color);
		background-color: var(--kt-form-select-disabled-bg);
		border-color: var(--kt-form-select-disabled-border-color);
	}

	// Remove outline from select box in FF
	&:-moz-focusring {
		text-shadow: 0 0 0 var(--kt-form-select-color);
	}
}

.form-select.is-invalid {
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
		no-repeat right 1rem center/15px 12px !important;

	// background-image: none !important;
}
.form-select.is-valid {
	// background-image: none;
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
		no-repeat right 1rem center/15px 12px !important;
}

// data:image/svg+xml,%3csvg xmlns=%27http://www.w3.o…-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e
