/** @format */

//
// Form controls
//

.form-control {
	color: var(--kt-input-color);
	background-color: var(--kt-input-bg);
	border: $input-border-width solid var(--kt-input-border-color);
	box-shadow: none !important;
	//@include box-shadow(var(--kt-input-box-shadow));

	&:focus {
		color: var(--kt-input-focus-color);
		background-color: var(--kt-input-focus-bg);
		border-color: var(--kt-input-focus-border-color);
		//box-shadow: var(--kt-input-focus-box-shadow);
	}

	// Placeholder
	&::placeholder {
		color: var(--kt-input-placeholder-color);
	}

	// Disabled and read-only inputs
	&:disabled,
	&[readonly] {
		color: var(--kt-input-disabled-color);
		background-color: var(--kt-input-disabled-bg);
		border-color: var(--kt-input-disabled-border-color);
	}

	// File input buttons theming
	&::file-selector-button {
		color: var(--kt-form-file-button-color);
		@include gradient-bg(var(--kt-form-file-button-bg));
		// border: 1px solid grey;
		// border-radius: 5px 0px 0px 5px;
		border: none;
		// margin-left: 1px;
		// padding: 3px;
		// padding-left: 1px;
		// padding-right: 1px;
	}

	&:hover:not(:disabled):not([readonly])::file-selector-button {
		background-color: #d7dadd;
	}
	// &:not(:disabled):not([readonly])::file-selector-button {
	// 	background-color: var(--kt-form-file-button-hover-bg);
	// 	// border: 1px solid grey;
	// 	// border-radius: 5px;
	// 	// background-color: red;
	// }
}

// Readonly controls as plain text
.form-control-plaintext {
	color: var(--kt-input-plaintext-color);
}

.form-control.is-invalid {
	background-image: none;
	&::file-selector-button {
		border: none;
	}
}
.form-control.is-valid {
	background-image: none;
	&::file-selector-button {
		border: none;
	}
}
