/** @format */

//
// Floating label
//

.form-floating {
	.form-control.form-control-solid {
		&::placeholder {
			color: transparent;
		}
	}
}
