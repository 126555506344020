/** @format */

html,
body,
#root {
	height: 100%;
	width: 100%;
	margin: 0;
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
}
.tippy-content {
	padding: 0;
}
html {
	position: absolute;
	top: 0;
	left: -50;
	padding: 0;
	overflow: auto;
}

body {
	top: 0!important;
}

/* New CSS for the Action for devextreme  button  */
.btn.btn-light.btn-active-light-primary.btn-sm.action {
	height: 2rem; 
	display: flex; 
	align-items: center; 
	justify-content: center;
  } 

	

.menu-container {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	position: absolute;
	z-index: 1000;
	min-width: 8rem;
	padding: 0.5rem 0;
	margin: 0;
	font-size: 1rem;
	text-align: left;
	list-style: none;
	background-color: #fff;
	border-radius: 0.25rem;
	left: -100px;
}

.menu-items:hover {
	cursor: pointer;
	background: rgb(240, 240, 240);
}

.menu-items.active {
	color: white;
}
.menu-items {
	display: block;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	border: 0;
}
.dropdown-search {
	position: auto;
  }