/** @format */

.ag-theme-alpine {
	/* border: 1px red; */
	/* --ag-borders: none;
	--ag-odd-row-background-color: #fff;
	--ag-selected-row-background-color: #efefef;
	--ag-width: 1000px; */
	--ag-font-family: "Inter", Helvetica, "sans-serif";
}

.ag-header {
	/* background-color: #fff;
	border-bottom: 2px solid #d6d6d6; */
}
.ag-cell {
	/* width: 200px; */
	/* background-color: red; */
}

.text-center * {
	justify-content: center;
}
