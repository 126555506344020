/** @format */

//
// App Navbar
//

.app-navbar {
	display: flex;
	align-items: stretch;

	.app-navbar-item {
		display: flex;
		align-items: center;
	}
	.app-navbar-item:hover {
		cursor: pointer;
	}

	&.app-navbar-stretch {
		.app-navbar-item {
			align-items: stretch;
		}
	}
}
