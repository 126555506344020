.custom-header-cell {
   font-weight: bold;
   color: #000;
  }
  .dx-datagrid .dx-datagrid-table .dx-header-row>td {
    padding-top: 15px !important;
    padding-bottom: 10px !important;
}

.dx-datagrid .dx-row>td{
    font-size: 13px !important;
    padding: 7px 7px !important;

}
.dx-texteditor-input {
    /* height: 25px !important; */
}
.dx-datagrid-headers .dx-header-row > .dx-datagrid-action {
  border-right: 1px solid #ccc; /* Adjust the color and thickness as needed */
}

.dx-datagrid-export-button .dx-icon {
  font-size: 25px; 
}

/* Increase the size of the column chooser icon */
.dx-datagrid-column-chooser-button .dx-icon {
  font-size: 25px !important; 
}

.disabled-row {
  pointer-events: none;
  opacity: 0.5;
  background-color: #f5f5f5;
}

/* Increase the height of the horizontal scrollbar */
.dx-scrollable-scrollbar-horizontal {
  height: 125px !important; /* Set desired scrollbar height */
}

/* Customize the scrollbar track */
.dx-scrollable-scrollbar .dx-scrollable-scroll {
  height: 100%; /* Ensures scrollbar fills the container height */
  background-color: #888; /* Scrollbar color */
  border-radius: 8px; /* Rounded scrollbar corners */
}

/* Track (scrollbar background) */
.dx-scrollable-scrollbar {
  background-color: #f1f1f1;
}

/* Change scrollbar color on hover */
.dx-scrollable-scroll:hover {
  background-color: #555;
}
