/** @format */

$--fc-event-border-color: #d6d6d6;

.MuiInputBase-root {
	height: 40px;
}
.MuiInputBase-input {
	font-size: medium;
}
.MuiInputBase-input:focus {
	border: 0;
}
.MuiInputBase-input:hover {
	border: 0;
}
.MuiInputBase-root:hover {
	border: 0;
}
.Mui-focused fieldset {
	border-color: "purple";
}
.MuiFormControl-root fieldset {
	// border: 0.5px solid var(--kt-input-border-color);
	border: 1px solid #e4e6ef;
	// border: 0;
	border-radius: 0.475rem;
}

.css-z3c6am-MuiFormControl-root-MuiTextField-root:focus {
	border: none;
}

.MuiPickersPopper-paper {
	position: fixed !important;
  }