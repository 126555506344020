/** @format */


.field:focus {
	border-color: #333e58;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.field {
	border-bottom: 1px solid black;
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border-radius: 0px;
	font-size: 20px;
}
/* Works for Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */

/* login module */
.cardSize {
	/* min-height: 49px; */
	width: 356px;
}


/* Aggrid Pagination panel responsiveness */
@media (max-width: 768px) {
	.ag-theme-alpine .ag-paging-panel {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		height: 80px;
	}

	.ag-theme-alpine .ag-paging-panel .ag-paging-page-summary-panel,
	.ag-theme-alpine .ag-paging-panel .ag-paging-buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1 1 50%;
		min-width: 50px;
		text-align: center;
		padding: 2px;
	}
}

@media (max-width: 480px) {
	.ag-theme-alpine .ag-paging-panel {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		height: 60px;
	}

	.ag-theme-alpine .ag-paging-panel .ag-paging-page-summary-panel,
	.ag-theme-alpine .ag-paging-panel .ag-paging-buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1 1 100%;
		min-width: 50px;
		text-align: center;
		padding: 2px;
	}
}

.custom-numberbox .dx-texteditor-input {
	height: 40px !important;
	line-height: 40px !important; /* Ensure the text is vertically centered */
	padding: 0 10px; /* Add horizontal padding if needed */
  }

  .custom-select__control {
	background-color: #f5f5f5;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	height: calc(2.25rem + 2px);
	color: #495057;
	box-shadow: none;
  }
  
  .custom-select__control--is-disabled {
	background-color: #e9ecef;
	color: #6c757d;
	cursor: not-allowed;
  }
  
  .custom-select__value-container {
	/* padding: 0; */
  }
  
  .custom-select__single-value {
	color: #495057;
  }
  
  .custom-select__placeholder {
	color: #6c757d;
  }

  pdf-viewer-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
  }
  
  .zoom-controls {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
	gap: 10px;
  }
  
  .pdf-document-container {
	flex-grow: 1;
	overflow: auto;
	border: 1px solid black;
	width: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .pagination {
	padding: 10px;
	text-align: center;
  }
  
  .navigation-buttons {
	display: flex;
	justify-content: center;
	gap: 10px;
  }
  
  button {
	padding: 5px 10px;
	font-size: 14px;
	cursor: pointer;
  }
  
  @media (max-width: 768px) {
	.zoom-controls,
	.navigation-buttons {
	  flex-direction: column;
	  gap: 5px;
	}
  
	.pdf-document-container {
	  max-height: 70vh; /* Adjust max height for mobile screens */
	  width: 100%;
	}
  
	button {
	  font-size: 12px;
	  padding: 5px;
	}
  }
  